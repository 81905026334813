import Meta from '../../components/Meta'
import { FC, useEffect, useState } from 'react'
import { useClient } from 'urql'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import {
  GetTestDocument,
  GetTestQuery,
  GetTestQueryVariables,
  useGetMaterialsQuery,
  useGetTagsQuery,
  useInsertTestMutation,
  useUpdateTestMutation,
} from '../../generated/urql.administrator'
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Form,
  Row,
  Toast,
  ToastContainer,
} from 'react-bootstrap'
import { ChevronLeft } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
import type { LocalizedField } from '../../lib/translations'
import { Locale } from '../../lib/translations'
import { TranslatedField } from '../../components/TranslatedField'
import isSvg from 'is-svg'

type FormTest = {
  shortName_i18n: LocalizedField
  name_i18n: LocalizedField
  description_i18n: LocalizedField
  preparation_i18n: LocalizedField
  instructions_i18n: LocalizedField
  labInstructions_i18n: LocalizedField
  svgIcon: string
  testTags: { tagId: string }[]
  materialIds: number[]
  mostPopular: boolean
  bloodlabEligible: boolean
}

const TestSettings: FC<{ id?: number; readOnly?: boolean }> = ({
  id,
  readOnly,
}) => {
  const pageTitle = 'Ustawienia'
  const client = useClient()
  const navigate = useNavigate()
  const [, setGetTestFetching] = useState(false)
  const [{ fetching: updateTestFetching }, updateTest] = useUpdateTestMutation()
  const [{ fetching: insertTestFetching }, insertTest] = useInsertTestMutation()
  const [{ data: tags, fetching: tagsFetching }] = useGetTagsQuery({
    variables: { limit: 1000 },
  })
  const [{ data: materialsData, fetching: materialsFetching }] =
    useGetMaterialsQuery()
  const [generalError, setGeneralError] = useState<string>()
  const [successToast, setSuccessToast] = useState(false)
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    register,
  } = useForm<FormTest>({
    defaultValues: {
      shortName_i18n: { pl_PL: '' },
      name_i18n: { pl_PL: '' },
      description_i18n: { pl_PL: '' },
      preparation_i18n: { pl_PL: '' },
      instructions_i18n: { pl_PL: '' },
      labInstructions_i18n: { pl_PL: '' },
      svgIcon: '',
      testTags: [],
      materialIds: [],
      mostPopular: false,
      bloodlabEligible: false,
    },
  })

  useEffect(() => {
    if (id) {
      setGetTestFetching(true)
      client
        .query<GetTestQuery, GetTestQueryVariables>(GetTestDocument, {
          id,
        })
        .toPromise()
        .then(({ data }) => {
          if (data?.test_by_pk) {
            reset({
              shortName_i18n: data.test_by_pk.shortName_i18n || { pl_PL: '' },
              name_i18n: data.test_by_pk.name_i18n || { pl_PL: '' },
              description_i18n: data.test_by_pk.description_i18n || {
                pl_PL: '',
              },
              preparation_i18n: data.test_by_pk.preparation_i18n || {
                pl_PL: '',
              },
              instructions_i18n: data.test_by_pk.instructions_i18n || {
                pl_PL: '',
              },
              labInstructions_i18n: data.test_by_pk.labInstructions_i18n || {
                pl_PL: '',
              },
              svgIcon: data.test_by_pk.svgIcon || '',
              testTags: data.test_by_pk.testTags || [],
              materialIds: data.test_by_pk.testMaterials.map(
                ({ materialId }) => materialId
              ),
              mostPopular: data.test_by_pk.mostPopular,
              bloodlabEligible: data.test_by_pk.bloodlabEligible,
            })
          }
        })
        .finally(() => setGetTestFetching(false))
    }
  }, [id, client, reset])

  async function doSaveTest({ materialIds, ...test }: FormTest) {
    setGeneralError(undefined)

    const { error, data } = id
      ? await updateTest({
          ...test,
          testTags: test.testTags.map(({ tagId }) => ({
            tagId,
            testId: id,
          })),
          testMaterials: materialIds.map((materialId) => ({
            testId: id,
            materialId,
          })),
          id,
        })
      : await insertTest({
          ...test,
          testTags: test.testTags.map(({ tagId }) => ({ tagId })),
          testMaterials: materialIds.map((materialId) => ({
            materialId,
          })),
        })

    if (error) {
      setGeneralError(error.message)
      return
    }

    setSuccessToast(true)

    if (data && 'insert_test_one' in data) {
      navigate(`/test/${data.insert_test_one?.id}`)
    }
  }

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-start">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() => navigate('/tests')}
          >
            <ChevronLeft size={20} />
            &nbsp;Wróć
          </Button>
        </ButtonGroup>
      </div>
      <Form onSubmit={handleSubmit(doSaveTest)} className="mt-4">
        {generalError && <Alert variant="danger">{generalError}</Alert>}

        <ToastContainer
          position="bottom-center"
          className="mb-4 bottom-center-toast-container"
        >
          <Toast
            onClose={() => setSuccessToast(false)}
            show={successToast}
            autohide
            animation={true}
            bg="success"
          >
            <Toast.Header>
              <strong className="me-auto">Sukces</strong>
            </Toast.Header>
            <Toast.Body>Pomyślnie zapisano badanie</Toast.Body>
          </Toast>
        </ToastContainer>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            ID
          </Form.Label>
          <Form.Label column sm="10">
            {id}
          </Form.Label>
        </Form.Group>

        <TranslatedField
          label="Nazwa skrócona"
          control={(locale) => (
            <>
              <Form.Control
                isInvalid={!!errors.shortName_i18n?.[locale]}
                type="text"
                disabled={readOnly}
                placeholder="Nazwa skrócona"
                {...register(`shortName_i18n.${locale}`, {
                  required:
                    locale === Locale.pl_PL ??
                    'Proszę podać nazwę skróconą badania',
                })}
              />
              {errors.shortName_i18n?.[locale] && (
                <Form.Control.Feedback>
                  {errors.shortName_i18n?.[locale]?.message}
                </Form.Control.Feedback>
              )}
            </>
          )}
        />

        <TranslatedField
          label="Nazwa pełna"
          control={(locale) => (
            <>
              <Form.Control
                isInvalid={!!errors.name_i18n?.[locale]}
                type="text"
                disabled={readOnly}
                placeholder="Nazwa pełna"
                {...register(`name_i18n.${locale}`, {
                  required:
                    locale === Locale.pl_PL ??
                    'Proszę podać nazwę pełną badania',
                })}
              />
              {errors.name_i18n?.[locale] && (
                <Form.Control.Feedback>
                  {errors.name_i18n?.[locale]?.message}
                </Form.Control.Feedback>
              )}
            </>
          )}
        />

        <TranslatedField
          label="Opis"
          control={(locale) => (
            <>
              <Form.Control
                isInvalid={!!errors.description_i18n?.[locale]}
                as="textarea"
                disabled={readOnly}
                rows={8}
                {...register(`description_i18n.${locale}`, {
                  required:
                    locale === Locale.pl_PL ?? 'Proszę podać opis badania',
                })}
              />
              {errors.description_i18n?.[locale] && (
                <Form.Control.Feedback>
                  {errors.description_i18n?.[locale]?.message}
                </Form.Control.Feedback>
              )}
            </>
          )}
        />

        <TranslatedField
          label="Przygotowanie do badania"
          control={(locale) => (
            <>
              <Form.Control
                isInvalid={!!errors.preparation_i18n?.[locale]}
                as="textarea"
                disabled={readOnly}
                rows={8}
                {...register(`preparation_i18n.${locale}`, {
                  required:
                    locale === Locale.pl_PL ??
                    'Proszę opisać przygotowanie do badania',
                })}
              />
              {errors.preparation_i18n?.[locale] && (
                <Form.Control.Feedback>
                  {errors.preparation_i18n?.[locale]?.message}
                </Form.Control.Feedback>
              )}
            </>
          )}
        />

        <TranslatedField
          label="Instrukcje dla osoby pobierającej"
          control={(locale) => (
            <>
              <Form.Control
                isInvalid={!!errors.instructions_i18n?.[locale]}
                as="textarea"
                disabled={readOnly}
                rows={8}
                {...register(`instructions_i18n.${locale}`, {
                  required:
                    locale === Locale.pl_PL ??
                    'Proszę podać instrukcje dla osoby pobierającej',
                })}
              />
              {errors.instructions_i18n?.[locale] && (
                <Form.Control.Feedback>
                  {errors.instructions_i18n?.[locale]?.message}
                </Form.Control.Feedback>
              )}
            </>
          )}
        />

        <TranslatedField
          label="Instrukcje dla laboratorium"
          control={(locale) => (
            <>
              <Form.Control
                isInvalid={!!errors.labInstructions_i18n?.[locale]}
                as="textarea"
                disabled={readOnly}
                rows={8}
                {...register(`labInstructions_i18n.${locale}`)}
              />
              {errors.labInstructions_i18n?.[locale] && (
                <Form.Control.Feedback>
                  {errors.labInstructions_i18n?.[locale]?.message}
                </Form.Control.Feedback>
              )}
            </>
          )}
        />

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Ikona badania (svg)
          </Form.Label>
          <Col sm="10">
            <Form.Control
              isInvalid={!!errors.svgIcon}
              disabled={readOnly}
              as="textarea"
              rows={8}
              {...register('svgIcon', {
                validate: (value) => {
                  return value && !isSvg(value)
                    ? 'Nieprawidłowy plik SVG'
                    : undefined
                },
              })}
            />
            {errors.svgIcon && (
              <Form.Control.Feedback>
                {errors.svgIcon.message}
              </Form.Control.Feedback>
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Kategorie
          </Form.Label>
          <Col sm="10">
            <Controller
              name="testTags"
              control={control}
              render={({ field: { value } }) => (
                <Select
                  isMulti
                  isLoading={tagsFetching}
                  value={value.map(({ tagId }) => ({
                    value: tagId,
                    label: tagId,
                  }))}
                  isDisabled={readOnly}
                  onChange={(newValue) =>
                    setValue(
                      'testTags',
                      newValue.map(({ value }) => ({ tagId: value }))
                    )
                  }
                  options={(tags?.tag || []).map(({ id }) => ({
                    value: id,
                    label: id,
                  }))}
                  placeholder="Wybierz kategorie"
                />
              )}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Materiały
          </Form.Label>
          <Col sm="10">
            <Controller
              name="materialIds"
              control={control}
              render={({ field: { value } }) => (
                <Select
                  isMulti
                  isDisabled={readOnly}
                  isLoading={materialsFetching}
                  value={value.map((materialId) => ({
                    value: materialId,
                    label: (materialsData?.material || []).find(
                      ({ id }) => id === materialId
                    )?.name,
                  }))}
                  onChange={(newValue) =>
                    setValue(
                      'materialIds',
                      newValue.map(({ value }) => value)
                    )
                  }
                  options={(materialsData?.material || []).map(
                    ({ id, name }) => ({
                      value: id,
                      label: name,
                    })
                  )}
                  placeholder="Wybierz materiał"
                />
              )}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Najpopularniejsze
          </Form.Label>
          <Col sm="10" className="py-2">
            <Form.Check
              type="switch"
              disabled={readOnly}
              {...register('mostPopular')}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Bloodlab
          </Form.Label>
          <Col sm="10" className="py-2">
            <Form.Check
              type="switch"
              disabled={readOnly}
              {...register('bloodlabEligible')}
            />
          </Col>
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          disabled={updateTestFetching || insertTestFetching || readOnly}
        >
          Zapisz
        </Button>
      </Form>
    </div>
  )
}

export default TestSettings
