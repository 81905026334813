import Meta from '../components/Meta'
import { FC, useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { useParams } from 'react-router'
import LaboratorySettings from './laboratory/LaboratorySettings'
import LaboratoryUsers from './laboratory/LaboratoryUsers'
import LaboratoryCollectionPoints from './laboratory/LaboratoryCollectionPoints'
import LaboratoryTests from './laboratory/LaboratoryTests'
import {
  GetLaboratoryDocument,
  GetLaboratoryQuery,
  GetLaboratoryQueryVariables,
} from '../generated/urql.administrator'
import { useClient } from 'urql'

const Laboratory: FC = () => {
  const pageTitle = 'Ustawienia'
  const { id } = useParams()
  const client = useClient()
  const [laboratory, setLaboratory] =
    useState<GetLaboratoryQuery['laboratory_by_pk']>()

  useEffect(() => {
    if (id) {
      client
        .query<GetLaboratoryQuery, GetLaboratoryQueryVariables>(
          GetLaboratoryDocument,
          {
            id: parseInt(id),
          }
        )
        .toPromise()
        .then(({ data }) => {
          if (data?.laboratory_by_pk) {
            setLaboratory(data.laboratory_by_pk)
          }
        })
    }
  }, [id, client])

  if (!id) {
    return <LaboratorySettings />
  }

  return (
    <div>
      <Meta title={pageTitle} />
      <Tabs defaultActiveKey="settings" className="mb-3 mt-3">
        <Tab eventKey="settings" title="Ustawienia">
          <LaboratorySettings id={parseInt(id)} />
        </Tab>
        <Tab eventKey="collectionPoints" title="Punkty pobrań">
          <LaboratoryCollectionPoints id={parseInt(id)} />
        </Tab>
        <Tab eventKey="users" title="Użytkownicy">
          <LaboratoryUsers id={parseInt(id)} />
        </Tab>
        <Tab eventKey="laboratoryTests" title="Badania">
          <LaboratoryTests
            id={parseInt(id)}
            useHL7={laboratory?.useHL7 || false}
          />
        </Tab>
      </Tabs>
    </div>
  )
}

export default Laboratory
