import Meta from '../../components/Meta'
import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Alert,
  Button,
  Col,
  FloatingLabel,
  Form,
  Image,
  Row,
  Toast,
  ToastContainer,
} from 'react-bootstrap'
import { useClient } from 'urql'
import {
  GetLaboratoryDocument,
  GetLaboratoryQuery,
  GetLaboratoryQueryVariables,
  useInsertLaboratoryMutation,
  usePingMutation,
  useUpdateLaboratoryMutation,
} from '../../generated/urql.administrator'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../provider/auth'
import { guessStorageAddress } from '../../lib/api'

type FormLaboratory = {
  name: string
  shortName?: string
  enabled: boolean
  useHL7: boolean
  logoFileId?: number
}

const LaboratorySettings: FC<{ id?: number }> = ({ id }) => {
  const pageTitle = 'Ustawienia laboratorium'
  const client = useClient()
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const [, setGetLaboratoryFetching] = useState(false)
  const [{ fetching: insertLaboratoryFetching }, insertLaboratory] =
    useInsertLaboratoryMutation()
  const [{ fetching: updateLaboratoryFetching }, updateLaboratory] =
    useUpdateLaboratoryMutation()
  const [generalError, setGeneralError] = useState<string>()
  const [successToast, setSuccessToast] = useState(false)
  const logoInputRef = useRef<HTMLInputElement>(null)
  const [logoFile, setLogoFile] = useState<File>()
  const [logoDataUrl, setLogoDataUrl] = useState<string | undefined>(
    'https://placehold.co/171x180@3x?text=%2B'
  )
  const [, ping] = usePingMutation()

  const {
    handleSubmit,
    reset,
    formState: { errors },
    register,
  } = useForm<FormLaboratory>({
    defaultValues: {
      name: '',
      enabled: true,
      useHL7: false,
    },
  })

  useEffect(() => {
    const reader = new FileReader()

    reader.addEventListener(
      'load',
      () => setLogoDataUrl(reader.result?.toString() || undefined),
      false
    )

    if (logoFile) {
      reader.readAsDataURL(logoFile)
    }
  }, [logoFile])

  const downloadFile = useCallback(
    async (id: number) => {
      try {
        const response = await fetch(`${guessStorageAddress()}/files/${id}`, {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        })

        const blob = await response.blob()
        return window.URL.createObjectURL(blob)
      } catch (error) {
        console.error(error)
      }
    },
    [auth]
  )

  useEffect(() => {
    if (id) {
      setGetLaboratoryFetching(true)
      client
        .query<GetLaboratoryQuery, GetLaboratoryQueryVariables>(
          GetLaboratoryDocument,
          {
            id,
          }
        )
        .toPromise()
        .then(async ({ data }) => {
          if (data?.laboratory_by_pk) {
            reset({
              name: data.laboratory_by_pk.name || '',
              shortName: data.laboratory_by_pk.shortName || undefined,
              enabled: data.laboratory_by_pk.enabled,
              useHL7: data.laboratory_by_pk.useHL7,
              logoFileId: data.laboratory_by_pk.logoFileId || undefined,
            })

            if (data.laboratory_by_pk.logoFileId) {
              setLogoDataUrl(
                await downloadFile(data.laboratory_by_pk.logoFileId)
              )
            }
          }
        })
        .finally(() => setGetLaboratoryFetching(false))
    }
  }, [id, client, reset])

  async function doSaveLaboratory({
    shortName,
    ...laboratory
  }: FormLaboratory) {
    setGeneralError(undefined)

    try {
      let logoFileId: number | undefined
      if (logoFile) {
        logoFileId = await uploadLogoImage(logoFile)
      }

      const { error, data } = id
        ? await updateLaboratory({
            ...laboratory,
            shortName: shortName || undefined,
            logoFileId,
            id,
          })
        : await insertLaboratory({ ...laboratory, logoFileId })

      if (error) {
        setGeneralError(error.message)
        return
      }

      setSuccessToast(true)

      if (data && 'insert_laboratory_one' in data) {
        navigate(`/laboratory/${data.insert_laboratory_one?.id}`)
      } else {
        window.location.reload()
      }
    } catch (error) {
      if (error instanceof Error) {
        setGeneralError(error.message)
      }
    }
  }

  const uploadLogoImage = useCallback(
    async (logoFile: File) => {
      await ping({}, { requestPolicy: 'network-only' })

      const formData = new FormData()
      formData.append('file', logoFile)
      formData.append('permission', 'public')

      const response = await fetch(`${guessStorageAddress()}/files`, {
        method: 'POST',
        body: formData,
        headers: {
          authorization: `Bearer ${auth.accessToken}`,
        },
      })

      if (!response.ok) {
        const error = await response.json()
        throw new Error(error.message)
      }

      return (await response.json()).id
    },
    [auth.accessToken, logoFile]
  )

  return (
    <div>
      <Meta title={pageTitle} />
      <Form onSubmit={handleSubmit(doSaveLaboratory)} className="mt-4">
        {generalError && <Alert variant="danger">{generalError}</Alert>}

        <ToastContainer
          position="bottom-center"
          className="mb-4 bottom-center-toast-container"
        >
          <Toast
            onClose={() => setSuccessToast(false)}
            show={successToast}
            autohide
            animation={true}
            bg="success"
          >
            <Toast.Header>
              <strong className="me-auto">Sukces</strong>
            </Toast.Header>
            <Toast.Body>Pomyślnie zapisano dane laboratorium</Toast.Body>
          </Toast>
        </ToastContainer>

        <Row>
          <Col>
            <h4>Dane laboratorium</h4>
            <FloatingLabel label="Nazwa" className="mb-3">
              <Form.Control
                isInvalid={!!errors.name}
                type="name"
                placeholder="Nazwa"
                {...register('name', {
                  required: 'Proszę podać nazwę',
                })}
              />
              {errors.name && (
                <Form.Control.Feedback>
                  {errors.name.message}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>

            <FloatingLabel label="Nazwa skrócona" className="mb-3">
              <Form.Control
                isInvalid={!!errors.shortName}
                type="text"
                placeholder="Nazwa skrócona"
                {...register('shortName')}
              />
              {errors.shortName && (
                <Form.Control.Feedback>
                  {errors.shortName.message}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>

            <Form.Check
              type="switch"
              label="Aktywne"
              {...register('enabled')}
            />
            {errors.enabled && (
              <Form.Control.Feedback>
                {errors.enabled.message}
              </Form.Control.Feedback>
            )}

            <Form.Check
              type="switch"
              label="Integracja HL7"
              {...register('useHL7')}
            />
            {errors.enabled && (
              <Form.Control.Feedback>
                {errors.enabled.message}
              </Form.Control.Feedback>
            )}

            <br />
            <h4>Logo</h4>
            <div
              onClick={(event) => {
                event.currentTarget
                  .querySelectorAll('input[type=file]')
                  .forEach((input: any) => input.click())
              }}
              className="d-inline-block"
              style={{ cursor: 'pointer' }}
            >
              <Image src={logoDataUrl} thumbnail />
              <input
                ref={logoInputRef}
                type="file"
                className="visually-hidden"
                accept="image/png, image/jpeg"
                onChange={async (event) => {
                  if (event.target.files?.length) {
                    setLogoFile(event.target.files[0])
                  }
                }}
              />
            </div>
          </Col>
        </Row>
        <Button
          className="mt-4"
          variant="primary"
          type="submit"
          disabled={updateLaboratoryFetching || insertLaboratoryFetching}
        >
          Zapisz
        </Button>
      </Form>
    </div>
  )
}

export default LaboratorySettings
